@import "~react-image-gallery/styles/scss/image-gallery.scss";

.image-gallery-slide {
    // transform: rotate(90deg)
    &:focus {
        outline: none; } }
.image-gallery-custom {
    transform: rotate(90deg) !important; }
.image-gallery-image {
    // transform: rotate(90deg)

    height: 200px;
    border-radius: 15px;
    background-size: cover; }
.image-gallery-slide .image-gallery-image {
    height: 300px; }
.image-gallery-icon {
    box-shadow: none;
    &:focus {
        outline: none !important; } }
.image-gallery-svg {
    height: 60px !important;
    &:hover {
        color: #006064; } }
.image-gallery-bullet {
    &:hover {
        background: #006064 !important; } }

.image-gallery-custom-left-nav {
    filter: drop-shadow(0 2px 2px #1a1a1a);
    cursor: pointer;
    top: 43%;
    left: 10%;
    z-index: 1;
    position: absolute;
    &:hover {
        color: #006064; } }
.image-gallery-custom-right-nav {
    filter: drop-shadow(0 2px 2px #1a1a1a);
    cursor: pointer;
    top: 43%;
    right: 10%;
    z-index: 1;
    position: absolute;
    &:hover {
        color: #006064; } }
